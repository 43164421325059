import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


// Inline styling

const style = {
  backgroundColor: "#011933",
  paddingTop: "6rem"
}

// end of styling

const Quiz = ({location}) => {
  return (
    <>
      <Layout location={location}>
        <SEO title="Quiz" description="" />
        <div className="page-content" style={style}>
          <div className="container-fluid px-0">
            <iframe title="LeadRoll Qualification Quiz" src="https://form.jotform.me/201606098319052" allowTransparency="true" allow="geolocation; microphone; camera" allowFullScreen="true" name={201405066935048} id={201405066935048} style={{width: '10px', minWidth: '100%', display: 'block', overflow: 'hidden', border: 'none', height: '640px'}} scrolling="no" />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Quiz
